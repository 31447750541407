html {
  height: 100%;
  font-family: 'Lato', sans-serif;
  color: $sgx-theme-v2-high-emp-default;
  -webkit-text-size-adjust: 100%;
}

body {
  height: 100%;
  width: 100%;
  display: flex;
}

/* Internet Explorer 10-11 fix */
input:-ms-input-placeholder {
  color: grey;
}

#website-main-loader {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100000;
}
.sgx-page-loader-title {
  margin-top: 8px;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
}
.sgx-page-loader {
  display: block;
  font-size: 1rem;
  width: 5em;
  height: 5em;
  overflow: hidden;
  background-image: linear-gradient(to bottom, #f4f4f4,#f4f4f4 50%, #e9e9e9 50%,#e9e9e9);
  background-size: 2em; //needed in order to enable a striped background

  &:after {
    content: attr(data-content);
    position: absolute;
    display: block;
    text-align: center;
    width: 100%;
    margin-top: 0.5em;
    font-size: 1.2em;
    color: $sgx-theme-v2-high-emp-default;
  }
}

.sgx-page-loader-line {
  height: 1em;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  &:nth-of-type(odd) {background: $v2-sgx-green;}
  &:nth-of-type(even) {background: $sgx-blue;}
  &:nth-of-type(1) {animation-name: sgx-page-loader-1;}
  &:nth-of-type(2) {animation-name: sgx-page-loader-2;}
  &:nth-of-type(3) {animation-name: sgx-page-loader-3;}
  &:nth-of-type(4) {animation-name: sgx-page-loader-4;}
  &:nth-of-type(5) {animation-name: sgx-page-loader-5;}
}

@keyframes sgx-page-loader-1 {
  0%,20%  {transform: translateX(-100%);}
  70% {transform: translateX(0);}
}

@keyframes sgx-page-loader-2 {
  0%,15%  {transform: translateX(100%);}
  65% {transform: translateX(0);}
}

@keyframes sgx-page-loader-3 {
  0%,10% {transform: translateX(-100%);}
  60% {transform: translateX(0);}
}

@keyframes sgx-page-loader-4 {
  0%,5% {transform: translateX(100%);}
  55% {transform: translateX(0);}
}

@keyframes sgx-page-loader-5 {
  0% {transform: translateX(-100%);}
  50% {transform: translateX(0);}
}

.website-main-layout {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  align-items: stretch;
  height: 100%;
  width: 100%;
  background-color: $sgx-theme-v2-bg-page-01;
}

.website-main-container {
  flex: 1 1 auto;
  height: 100%;
  overflow: auto;
}

.website-main-content {
  opacity: 1;
  transition: opacity 0.1s ease;
  height: 100%;
  background: $sgx-theme-v2-bg-page-02;
  display: flex;
  flex-direction: column;
  font-size: 16px;

  & > * {
    flex: 0 0 auto;
  }

  .router-transition-in-progress & {
    opacity: 0;
    pointer-events: none;
  }
}

#page-container {
  flex: 1; /* WARNING - Do not change this property, it breaks the securities detail page :'( */
  display: flex;
  flex-direction: column;
  width: 100%;
  background: $sgx-theme-v2-bg-page-02;
}

.website-main-background {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  z-index: -1;
  transition: opacity 0.5s ease;
  opacity: 0;
  text-align: center;
  font-size: 1rem;

  .router-transition-in-progress & {
    opacity: 1;
    transition-delay: 1s;
    z-index: 100;
  }
}

.website-main-loader {
  opacity: 0;
  animation-play-state: paused;
  transition: opacity 0.75s;

  .router-transition-in-progress & {
    opacity:1;
    animation-play-state: running;
    transition-delay: 1.5s;
  }
}

.website-overflow-wrapper {
  overflow-x: auto;
  font-size: 0;
}

.website-overflow-content {
  display: inline-block;
  font-size: 1rem;
  min-width: 100%;
}

body[data-type="mobile"] {
  .website-main-background {
    font-size: 0.8rem;
  }

  .website-main-content {
    font-size: 0.8rem;
  }
}

.d-none { display: none !important; }
