@import "sgx-status-indicator/styles";

$page-content-padding: 2rem 10% !default;
$page-content-tablet-padding: 2rem 1rem !default;
$page-content-mobile-padding: 1rem !default;
$page-content-font-family: Lato, sans-serif !default;
$page-content-line-height: 1.4 !default;
$page-content-text-align: left !default;
$page-content-h3-font-size: 125% !default;
$page-content-h3-font-color: $sgx-dark-grey !default;
$page-content-h3-font-weight: bold !default;

.pages-content {
  color: $sgx-dark-grey;
  font-family: $page-content-font-family;
  line-height: $page-content-line-height;
  text-align: $page-content-text-align;
  padding: $page-content-padding;

  h3 {
    color: $page-content-h3-font-color;
    font-size: $page-content-h3-font-size;
    font-weight: $page-content-h3-font-weight;
  }

  p {
    padding: 0.5rem 0 0 0 ;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  .row {
    margin-left: $space-1;
    margin-right: $space-1;
  }

  .sgx-fpds-consent-logo-container {
    width: 100%;
    margin-bottom: $space-5;
  }

  .sgx-fpds-consent-logo {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 50%;
    display: block;
    height: 5rem;
  }

  .sgx-fpds-consent-content {
    text-align: justify;
  }

  .sgx-fpds-consent-indented-paragraph {
    margin-left: 5%;
  }

  .sgx-fpds-consent-double-indented-paragraph {
    margin-left: 10%;
  }

  .sgx-fpds-consent-indented-content {
    padding-left: $space-1;
  }

  .sgx-fpds-consent-actions {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-top: $space-8;
  }

  button {
    padding: 0 $space-10;

    &:focus, &:hover, &:visited, &:active {
      outline: none !important;
      outline: 0 !important;
    }
  }

}

body[data-type='tablet'] {

  .pages-content {
    padding: $page-content-tablet-padding;
  }

}

body[data-type='mobile'] {

  .pages-content {
    padding: $page-content-mobile-padding;
  }

}
