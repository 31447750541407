$sgx-unsupported-browser-sgx-logo-width: 180px !default;
$unsupported-browser-title-font-size: 2rem;
$unsupported-browser-title-font-size-mobile: 26px;
$unsupported-browser-title-font-color: #0b236b !default;
$unsupported-browser-title-margin: 48px 0 32px 0 !default;

.unsupported-browser {
  width: 100%;
  height: 100%;
  line-height: 1.4;

  .sgx-logo {
    width: $sgx-unsupported-browser-sgx-logo-width;
  }
  @media (min-width: 376px) {
    align-items: center;
  }
}

.unsupported-browser-content {
  margin: 40px auto;
  max-width: 1200px;
  padding: 20px;

  p {
    font-size: 1.2rem;
    margin-bottom: 16px;
  }

  @include media-breakpoint-down('sm') {
    margin: 0;
    padding: 40px 20px;
    p {
      font-size: 16px;
    }
  }
}

.unsupported-browser-title {
  font-size: $unsupported-browser-title-font-size;
  margin: $unsupported-browser-title-margin;
  color: $unsupported-browser-title-font-color;

  @include media-breakpoint-down('sm') {
    font-size: $unsupported-browser-title-font-size-mobile;
  }
}

.unsupported-browser-illustration--mobile,
.unsupported-browser-illustration {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.unsupported-browser-illustration {
  @include media-breakpoint-down('sm') {
    img {
      display: none;
    }
  }
}

.unsupported-browser-illustration--mobile {
  img {
    display: none;
    margin-bottom: 2rem;
  }

  @include media-breakpoint-down('sm') {
    img {
      display: block;
      width: 300px;
    }
  }
}

.supported-browser-list {
  padding: 2rem 0;

  .browser-icon {
    text-align: center;
    text-decoration: none;
    color: inherit;
    line-height: 2.25rem;
    img {
      width: 4rem;
    }
    @include media-breakpoint-down('sm') {
      line-height: 1.5rem;
      margin-bottom: 22px
    }
  }
}

.contact-us a {
  color: $sgx-light-blue;
}

.unsupported-browser {
  display: none;

  body.no-browser-support & {
    display: block;
  }
}
